import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import axios from "axios";

const images = [
  require("../../Assets/recycling-at-home-background-free-vector.jpg"), 
  require("../../Assets/main.gif"),
  require("../../Assets/main.jpeg"),
  require("../../Assets/main2.jpg"),
];

const Home = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [isThankYouVisible, setIsThankYouVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); 

    return () => clearInterval(interval);
  }, []);

  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleNextClick = async () => {
    if (
      mobileNumber.length === 10 &&
      name &&
      address &&
      pickupDate &&
      pickupTime
    ) {
      setLoading(true);
      try {
        await handleFormSubmit();
      } catch (error) {
        console.log(error);
        alert("Error saving details. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in all fields correctly.");
    }
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      await axios.post(
        "https://backend-production-af1c.up.railway.app/api/pickup",
        {
          mobileNumber,
          name,
          address,
          pickupDate,
          pickupTime,
        }
      );

      alert("Details saved successfully!");
      setIsThankYouVisible(true);
    } catch (error) {
      alert("Error saving details. Please try again.");
    }
  };

  return (
    <div className="homeScreen">
      <div className="leftHome">
        <div className="leftupper">
          <h3>Sell your recyclables</h3>
          <h3>
            online with <span className="scrapify">Apna Kabad!</span>
          </h3>
          <h2>Paper-Plastics-Metals-Appliances</h2>
          {/*<h2 className="download">
            You can also download the{" "}
            <span className="downloadlink">Scrapify App!</span>
          </h2>*/}
          <img src={images[currentImageIndex]} alt="Recycling" className="homeimg" />
        </div>
      </div>

      <div className="rightHome">
        {!isThankYouVisible && (
          <div className="card">
            <h2>Schedule Pick Up</h2>
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Address:</label>
              <input
                type="text"
                placeholder="Enter your address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Mobile Number:</label>
              <input
                type="tel"
                maxLength="10"
                placeholder="Enter your Mobile Number"
                value={mobileNumber}
                onChange={handleMobileChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Pick Up Date:</label>
              <input
                type="date"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Pick Up Time:</label>
              <input
                type="time"
                value={pickupTime}
                onChange={(e) => setPickupTime(e.target.value)}
                required
              />
            </div>
            <button className="next-button" onClick={handleNextClick}>
              {loading ? "Loading..." : "Next"}
            </button>
            <p className="help-text">
              Facing any problem? Call us{" "}
              <a href="tel:+917974576441" className="phone-link">
                +917974576441
              </a>
            </p>
            <button className="franchise-button">
              <Link
                to="/franchise-registration"
                style={{ textDecoration: "none", color: "white" }}
              >
                Become a Franchise Partner
              </Link>
            </button>
          </div>
        )}

        {isThankYouVisible && (
          <div className="thank-you-message">
            <h2>Thank you for scheduling the pick up!</h2>
            <p>
              We will pick up your recyclables on the scheduled date and time.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
