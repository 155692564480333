import { Routes, Route } from "react-router-dom"; // No need to import Router again
import Navbar from "./Components/navbar"; // Adjust the path if needed
import Home from "./Components/Home/home";
import WhyUs from "./Components/WhyUs/whyus";
import Works from "./Components/Works/works";
import Footer from "./Components/Footer/footer";
import ScrapeRates from "./Components/ScraprRates/scraperates"; 
import FranchiseRegistration from "./Components/Franchise/franchise";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/franchise-registration" element={<FranchiseRegistration />} />
        <Route path="/scrape-rates" element={<ScrapeRates />} />
      </Routes>
      <WhyUs />
      <Works />
      <Footer />
    </div>
  );
}

export default App;
