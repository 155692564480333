import React from 'react'
import './navbar.css'
import Navbar from './Navbar/navbarbelt'
const navbar = () => {
  return (
    <div className='navbar'>
      <Navbar/>
    </div>
  )
}

export default navbar
